import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    date: "cfg_date",
    version: "cfg_version",
    addToHomeScreenMessageIsClosed: "cfg_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "cfg_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "cfg_has-used-home-screen-app",
    updateAvailable: "cfg_update_available",
    ...sharedStorageKeys
};

export default storageKeys;