const enGb = {
    global: {
        addRow: "Add row",
        apply: "Apply",
        cancel: "Cancel",
        changeRole: "Change role",
        changeBranch: "Change branch",
        changeRoleFrom: (userName) => `Change role from ${userName}`,
        changeBranchFrom: (userName) => `Vestiging wijzigen van ${userName}`,
        changes: "Changes",
        chooseFile: "Choose file",
        configurations: "Configurations",
        creationDate: "Creation date",
        csvUpload: "CSV upload",
        current: "Current",
        new: "New",
        delete: "Delete",
        downloadCsvExample: "Download example (.csv)",
        email: "E-mail address",
        empty: "Empty",
        expirationDate: "Expiration date",
        individual: "Individual",
        invite: "Invite",
        inviteUsers: "Invite users",
        name: "Name",
        no: "No",
        noChanges: "There are no changes yet",
        noFileSelected: "No file selected",
        noInvitesFound: "No invitations were found",
        pendingInvitations: "Open invitations",
        role: "Role",
        branch: "Branch",
        saveChanges: "Save Changes",
        savedChanges: "The changes have been saved",
        searchPlaceholder: "Search...",
        selectARole: "Select a role",
        selectABranch: "Select a branch",
        userOverview: "User overview",
        users: "Users",
        xUsersFound: (count = 0) => `${count} users found`,
        yes: "Yes",
        language: "Language",
        group: "Group",
        change: "Change",
        changeImage: (name) => `Change ${name}`,
        noImageYet: (name) => `No ${name} yet`,
        uploadNewImage: (name) => `Upload new ${name}`,
        or: "Or",
        navigateToGroup: "Navigate to group",
        searchBySetting: "Search by setting",
        noSettingsFound: "No settings found",
        undoAllChanges: "Undo all",
        noChangesYet: "There are no changes yet",
        viewChange: "View change",
        switchLanguage: "Switch language",
        dontSave: "Don't save",
        save: "Save",
        imageUpdatedReloadApp: (field = "") => `${field} has been changed, the app needs to be reloaded to see it here.`,
        saveChangesFor: "Save change(s) for"
    },
    navigation: {
        configurations: "Configurations",
        userManagement: {
            userManagement: "User management",
            invitations: "Pending invitations",
            invite: "Invite",
            overview: "Overview"
        }
    },
    messages: {
        loading: {
            default: "Loading..."
        },
        error: {
            csvImportRow: (row) => `An error has been found. Check row ${row}`,
            csvImportUnknownRole: (row, roleName) => `Role "${roleName}" was not found on row ${row} in the CSV file. Note spaces and case sensitivity.`,
            csvImportUnknownBranch: (row, branchCode) => `Branch with code "${branchCode}" was not found on row ${row} in the CSV file. Note spaces and case sensitivity.`,
            unableToDeleteInvite: "An error has occurred. The invitation cannot be deleted.",
            unableToDeleteUser: "An error has occurred. The user cannot be deleted.",
            unableToSaveFollowingGroups: "Something went wrong while saving changes. The following group(s) were not saved:",
            languageHasNotBeenChanged: "<b>Note:</b> the language has not changed because of this."
        },
        confirmation: {
            deleteInvite: (email) => `Are you sure you want to delete the invitation for <strong>${email}</strong>?`,
            deleteUser: (userName) => `Are you sure you want to remove <strong>${userName}</strong> as a user?`,
            saveChangesBeforeSwitchingLanguage: "Would you like to save your changes before switching languages?",
            areYouSureSaveChanges: "Are you sure you want to save the pending changes?"
        },
        success: {
            csvImportUsersFound: (count) => `There are ${count} users found in the file`,
            invitationsSend: "The invitations have been sent",
            invitationsSendQueue: "It can take a few minutes before the invitations are sent and are shown under 'Open invitations'.",
            changesHaveBeenSaved: "The changes were saved successfully.",
            theFollowingGroupsHaveBeenSaved: "The following groups have been saved successfully:"
        },
        
        validation: {
            numberRequired: "Dit veld mag alleen getallen bevatten.",
            hexadecimalRequired: "Dit veld mag alleen hexadecimale kleuren bevatten.",
            invalidOption: "Dit veld mag alleen de beschikbare opties bevatten.",
            invalidFileName: (fileName) => `De afbeelding die u probeert te uploaden heeft een incorrecte bestandsnaam. De bestandsnaam moet exact "${fileName}" zijn.`,
            invalidBoolean: "Dit veld mag alleen 'aan' of 'uit' staan.",
            requiredEmail: "Please enter a valid email address",
            requiredField: "Required field"
        }
    }
};

export default enGb;
