const deDe = {
    global: {
        addRow: "Regel hinzufügen",
        apply: "Bewerben",
        cancel: "Absagen",
        changeRole: "Rolle wechseln",
        changeBranch: "Den Ort wechseln",
        changeRoleFrom: (userName) => `Rolle von ${userName} ändern`,
        changeBranchFrom: (userName) => `Vestiging wijzigen van ${userName}`,
        changes: "Änderungen",
        chooseFile: "Datei wählen",
        configurations: "Konfigurationen",
        creationDate: "Erstellungsdatum",
        csvUpload: "CSV-Upload",
        current: "Aktuell",
        new: "Neu",
        delete: "Löschen",
        downloadCsvExample: "Beispiel herunterladen (.csv)",
        email: "E-Mail Addresse",
        empty: "Leer",
        expirationDate: "Verfallsdatum",
        individual: "Individuell",
        invite: "Einladen",
        inviteUsers: "Benutzer einladen",
        name: "Name",
        no: "Nein",
        noChanges: "Es gibt noch keine Änderungen",
        noFileSelected: "Keine Datei ausgewählt",
        noInvitesFound: "Es wurden keine Einladungen gefunden",
        pendingInvitations: "Einladungen öffnen",
        role: "Rolle",
        branch: "Vestiging",
        saveChanges: "Änderungen speichern",
        savedChanges: "Die Änderungen wurden gespeichert",
        searchPlaceholder: "Suche...",
        selectARole: "Wählen Sie eine Rolle aus",
        selectABranch: "Wähle einen Ort",
        userOverview: "Benutzerübersicht",
        users: "Benutzer",
        xUsersFound: (count = 0) => `${count} Benutzer gefunden`,
        yes: "Ja",
        language: "Sprache",
        group: "Gruppe",
        change: "Ändern",
        changeImage: (name) => `${name} Ändern`,
        noImageYet: (name) => `Noch kein ${name}`,
        uploadNewImage: (name) => `Neues ${name} hochladen`,
        or: "Oder",
        navigateToGroup: "Zur Gruppe navigieren",
        searchBySetting: "Suche nach Institution",
        noSettingsFound: "Keine Einstellungen gefunden",
        undoAllChanges: "Alles rückgängig machen",
        noChangesYet: "Es gibt noch keine Änderungen",
        viewChange: "Änderung anzeigen",
        switchLanguage: "Sprache wechseln",
        dontSave: "Rette nicht",
        save: "Speichern",
        imageUpdatedReloadApp: (field) => `Das ${field?.toLowerCase()} wurde geändert. Die App muss neu geladen werden, um es hier zu sehen.`,
        saveChangesFor: "Änderungen speichern für"
    },
    navigation: {
        configurations: "Konfigurationen",
        userManagement: {
            userManagement: "Benutzerverwaltung",
            invitations: "Ausstehende Einladungen",
            invite: "Einladen",
            overview: "Überblick"
        }
    },
    messages: {
        loading: {
            default: "Wird geladen..."
        },
        error: {
            csvImportRow: (row) => `Es wurde ein Fehler gefunden. Zeile ${row} prüfen`,
            csvImportUnknownRole: (row) => `Rolle in Zeile ${row} nicht gefunden. Beachten Sie Leerzeichen und Groß- und Kleinschreibung.`,
            csvImportUnknownBranch: (row, branchCode) => `Vestiging met code "${branchCode}" niet gevonden op regel ${row} in het CSV bestand. Let op spaties en hoofdlettergevoeligheid.`,
            unableToDeleteInvite: "Ein Fehler ist aufgetreten. Die Einladung kann nicht gelöscht werden.",
            unableToDeleteUser: "Ein Fehler ist aufgetreten. Der Benutzer kann nicht gelöscht werden.",
            unableToSaveFollowingGroups: "Beim Speichern der Änderungen ist ein Fehler aufgetreten. Die folgenden Gruppen wurden nicht gespeichert:",
            languageHasNotBeenChanged: "<b>Hinweis:</b> Die Sprache hat sich dadurch nicht geändert."
        },
        confirmation: {
            deleteInvite: (email) => `Möchten Sie die Einladung an <strong>${email}</strong> wirklich löschen?`,
            deleteUser: (userName) => `Möchten Sie <strong>${userName}</strong> wirklich als Benutzer entfernen?`,
            saveChangesBeforeSwitchingLanguage: "Möchten Sie Ihre ausstehenden Änderungen speichern, bevor Sie die Sprache wechseln?",
            areYouSureSaveChanges: "Sind Sie sicher, dass Sie die ausstehenden Änderungen speichern möchten?"
        },
        success: {
            csvImportUsersFound: (count) => `Es wurden ${count} Benutzer in der Datei gefunden`,
            invitationsSend: "Die Einladungen wurden verschickt",
            invitationsSendQueue: "Es kann einige Minuten dauern, bis die Einladungen versendet und unter „Offene Einladungen“ angezeigt werden.",
            changesHaveBeenSaved: "Die Änderungen wurden erfolgreich gespeichert.",
            theFollowingGroupsHaveBeenSaved: "Die folgenden Gruppen wurden erfolgreich gespeichert:"
        },
        validation: {
            numberRequired: "Dieses Feld kann nur Zahlen enthalten.",
            hexadecimalRequired: "Dieses Feld darf nur hexadezimale Farben enthalten.",
            invalidOption: "Dieses Feld sollte nur die verfügbaren Optionen enthalten.",
            invalidFileName: (fileName) => `Das Bild, das Sie hochladen möchten, hat einen falschen Dateinamen. Der Dateiname muss genau "${fileName}" lauten.`,
            invalidBoolean: "Dieses Feld darf nur 'an' oder 'aus' sein.",
            requiredEmail: "Bitte geben Sie eine gültige E-Mail Adresse ein",
            requiredField: "Pflichtfeld"
        }
    }
};

export default deDe;
