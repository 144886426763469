import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { sharedRoutes } from "./sharedRoutes";

const ConfigurationsPage = lazy(() => import("pages/ConfigurationsPage"))
const UserManagementInvitations = lazy(() => import("pages/UserManagement/UserManagementInvitations"));
const UserManagementInvite = lazy(() => import("pages/UserManagement/UserManagementInvite"));
const UserManagementOverview = lazy(() => import("pages/UserManagement/UserManagementOverview"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "configuration",
                path: "/",
                title: "navigation.configurations",
                index: true,
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ConfigurationsPage />
                    </Suspense>
                ),
                children: []
            },

            {
                key: "userManagementInvite",
                path: "/user-management/invite",
                title: "navigation.userManagement.userManagement",
                inMenu: true,
                matchEnd: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <UserManagementInvite />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "userManagementInvitations",
                path: "/user-management/invitations",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <UserManagementInvitations />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "userManagementOverview",
                path: "/user-management/overview",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <UserManagementOverview />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "/profile",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
            ...sharedRoutes
        ]
    },


];
