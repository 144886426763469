const frFr = {
    global: {
        addRow: "Ajouter une rangée",
        apply: "Appliquer",
        cancel: "Annuler",
        changeRole: "Changer de rôle",
        changeBranch: "Changer de lieu",
        changeRoleFrom: (userName) => `Changer le rôle de ${userName}`,
        changeBranchFrom: (userName) => `Vestiging wijzigen van ${userName}`,
        changes: "Changements",
        chooseFile: "Choisir le fichier",
        configurations: "Configurations",
        creationDate: "Date de création",
        csvUpload: "Téléchargement CSV",
        current: "Courant",
        new: "Nouveau",
        delete: "Effacer",
        downloadCsvExample: "Télécharger l'exemple (.csv)",
        email: "Adresse e-mail",
        empty: "Vide",
        expirationDate: "Date d'expiration",
        individual: "Individuel",
        invite: "Inviter",
        inviteUsers: "Inviter des utilisateurs",
        name: "Nom",
        no: "Non",
        noChanges: "Il n'y a pas encore de changements",
        noFileSelected: "Aucun fichier sélectionné",
        noInvitesFound: "Aucune invitation n'a été trouvée",
        pendingInvitations: "Invitations ouvertes",
        role: "Rôle",
        branch: "Bifurquer",
        saveChanges: "Enregistrer les modifications",
        savedChanges: "Les changements ont été enregistrés",
        searchPlaceholder: "Chercher...",
        selectARole: "Sélectionnez un rôle",
        selectABranch: "sélectionner un emplacement",
        userOverview: "Présentation de l'utilisateur",
        users: "Utilisateurs",
        xUsersFound: (count = 0) => `${count} utilisateurs trouvés`,
        yes: "Oui",
        language: "Langue",
        group: "Groupe",
        change: "Modifier",
        changeImage: (name) => `${name}change`,
        noImageYet: (name) => `Pas encore de ${name}`,
        uploadNewImage: (name) => `Télécharger un nouveau ${name}`,
        or: "Ou",
        navigateToGroup: "Naviguer vers le groupe",
        searchBySetting: "Recherche par établissement",
        noSettingsFound: "Aucun paramètre trouvé",
        undoAllChanges: "Tout annuler",
        noChangesYet: "Il n'y a pas encore de changements",
        viewChange: "Afficher le changement",
        switchLanguage: "Changer de langue",
        dontSave: "Ne pas enregistrer",
        save: "Sauvegarder",
        imageUpdatedReloadApp: (field) => `le ${field?.toLowerCase()} a été modifié, il faut recharger l'application pour le voir ici.`,
        saveChangesFor: "Enregistrer les modifications pour"
    },
    navigation: {
        configurations: "configurations",
        userManagement: {
            userManagement: "Gestion des utilisateurs",
            invitations: "Invitations en attente",
            invite: "Inviter",
            overview: "Aperçu"
        }
    },
    messages: {
        loading: {
            default: "Chargement..."
        },
        error: {
            csvImportRow: (row) => `Une erreur a été trouvée. Vérifier la ligne ${row}`,
            csvImportUnknownRole: (row, roleName) => `Rôle "${roleName}" introuvable sur la ligne ${row} dans le fichier CSV. Faites attention aux espaces et à la sensibilité à la casse.`,
            csvImportUnknownBranch: (row, branchCode) => `Branche avec le code "${branchCode}" introuvable sur la ligne ${row} dans le fichier CSV. Faites attention aux espaces et à la sensibilité à la casse.`,
            unableToDeleteInvite: "Une erreur est survenue. L'invitation ne peut pas être supprimée.",
            unableToDeleteUser: "Une erreur est survenue. L'utilisateur ne peut pas être supprimé.",
            unableToSaveFollowingGroups: "Une erreur s'est produite lors de l'enregistrement des modifications. Le(s) groupe(s) suivant(s) n'ont pas été enregistrés :",
            languageHasNotBeenChanged: "<b>Remarque :</b> la langue n'a pas changé pour cette raison."
        },
        confirmation: {
            deleteInvite: (email) => `Voulez-vous vraiment supprimer l'invitation à <strong>${email}</strong>?`,
            deleteUser: (userName) => `Voulez-vous vraiment supprimer <strong>${userName}</strong> en tant qu'utilisateur?`,
            saveChangesBeforeSwitchingLanguage: "Voulez-vous enregistrer vos modifications en attente avant de changer de langue?",
            areYouSureSaveChanges: "Êtes-vous sûr de vouloir enregistrer les modifications en attente ?"
        },
        success: {
            csvImportUsersFound: (count) => `Il y a ${count} utilisateurs trouvés dans le fichier`,
            invitationsSend: "Les convocations ont été envoyées",
            invitationsSendQueue: `L'envoi des invitations peut prendre quelques minutes et s'affiche sous "Ouvrir les invitations".`,
            changesHaveBeenSaved: "Les modifications ont été enregistrées avec succès.",
            theFollowingGroupsHaveBeenSaved: "Les groupes suivants ont été enregistrés avec succès :"
        },
        validation: {
            numberRequired: "Ce champ ne peut contenir que des chiffres.",
            hexadecimalRequired: "Ce champ ne peut contenir que des couleurs hexadécimales.",
            invalidOption: "Ce champ ne doit contenir que les options disponibles.",
            invalidFileName: (fileName) => `L'image que vous essayez de télécharger a un nom de fichier incorrect. Le nom du fichier doit être exactement "${fileName}".`,
            invalidBoolean: "Ce champ ne peut être que 'on' ou 'off'.",
            requiredEmail: "S'il vous plaît, mettez une adresse email valide",
            requiredField: "Champs requis"
        }
    }
};

export default frFr;
