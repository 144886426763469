import { lazy, Suspense } from "react";

const Organization = lazy(() => import("pages/Organization"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));

export const sharedRoutes = [
    {
        path: "organization/:name",
        element: (
            <Suspense fallback={<></>}>
                <Organization />
            </Suspense>
        ),
        children: []
    },
    {
        path: "*",
        element: (
            <Suspense fallback={<></>}>
                <NotFoundPage />
            </Suspense>
        ),
        children: []
    }
];
