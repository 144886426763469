const nlNl = {
    global: {
        addRow: "Nieuwe regel",
        apply: "Toepassen",
        cancel: "Annuleren",
        changeRole: "Rol wijzigen",
        changeBranch: "Vestiging wijzigen",
        changeRoleFrom: (userName) => `Rol wijzigingen van ${userName}`,
        changeBranchFrom: (userName) => `Vestiging wijzigen van ${userName}`,
        changes: "Wijzigingen",
        chooseFile: "Kies bestand",
        configurations: "Configuraties",
        creationDate: "Aanmaakdatum",
        csvUpload: "CSV upload",
        current: "Huidig",
        new: "Nieuw",
        delete: "Verwijderen",
        downloadCsvExample: "Download voorbeeld (.csv)",
        email: "E-mailadres",
        empty: "Leeg",
        expirationDate: "Verloopdatum",
        individual: "Individueel",
        invite: "Uitnodigen",
        inviteUsers: "Gebruikers uitnodigen",
        name: "Naam",
        no: "Nee",
        noChanges: "Er zijn nog geen wijzigingen",
        noFileSelected: "Geen bestand geselecteerd",
        noInvitesFound: "Er zijn geen uitnodigingen gevonden",
        pendingInvitations: "Openstaande uitnodigingen",
        role: "Rol",
        branch: "Vestiging",
        saveChanges: "Wijzigingen opslaan",
        savedChanges: "De wijzigen zijn opgeslagen",
        searchPlaceholder: "Zoeken...",
        selectARole: "Selecteer rol",
        selectABranch: "Selecteer vestiging",
        userOverview: "Overzicht gebruikers",
        users: "Gebruikers",
        xUsersFound: (count = 0) => `${count} gebruikers gevonden`,
        yes: "Ja",
        language: "Taal",
        group: "Groep",
        change: "Wijzigen",
        changeImage: (name) => `${name} wijzigen`,
        noImageYet: (name) => `Nog geen ${name}`,
        uploadNewImage: (name) => `Nieuw ${name} uploaden`,
        or: "Of",
        navigateToGroup: "Navigeer naar groep",
        searchBySetting: "Zoek op instelling",
        noSettingsFound: "Geen instellingen gevonden",
        undoAllChanges: "Alles ongedaan maken",
        noChangesYet: "Er zijn nog geen wijzigingen",
        viewChange: "Wijziging bekijken",
        switchLanguage: "Taal wisselen",
        dontSave: "Niet opslaan",
        save: "Opslaan",
        imageUpdatedReloadApp: (field) => `${field} is aangepast, de app dient herladen te worden om deze hier te zien.`,
        saveChangesFor: "Wijziging(en) opslaan voor"
    },
    navigation: {
        configurations: "Configuraties",
        userManagement: {
            userManagement: "User management",
            invitations: "Openstaande uitnodigingen",
            invite: "Uitnodigingen",
            overview: "Overzicht"
        }
    },
    messages: {
        loading: {
            default: "Laden..."
        },
        error: {
            csvImportRow: (row) => `Er is een fout gevonden. Controleer regel ${row}`,
            csvImportUnknownRole: (row, roleName) => `De rol genaamd "${roleName}" op rij ${row} in het CSV bestand is geen beschikbare rol. Let op spaties en hoofdlettergevoeligheid.`,
            csvImportUnknownBranch: (row, branchCode) => `De vestiging met de code "${branchCode}" op rij ${row} in het CSV bestand is geen beschikbare vestiging. Let op spaties en hoofdlettergevoeligheid.`,   
            unableToDeleteInvite: "Er is een fout opgetreden. De uitnodiging kan niet verwijderd worden.",
            unableToDeleteUser: "Er is een fout opgetreden. De gebruiker kan niet verwijderd worden.",
            unableToSaveFollowingGroups: "Er is iets fout gegaan bij het opslaan van de wijzigingen. De volgende groep(en) zijn niet opgeslagen:",
            languageHasNotBeenChanged: "<b>Let op:</b> de taal is hierdoor niet veranderd."
        },
        confirmation: {
            deleteInvite: (email) => `Weet je zeker dat je de uitnodiging voor <strong>${email}</strong> wilt verwijderen?`,
            deleteUser: (userName) => `Weet je zeker dat je <strong>${userName}</strong> wilt verwijderen als gebruiker?`,
            saveChangesBeforeSwitchingLanguage: "Wilt u de openstaande wijzigingen opslaan voordat u van taal wisselt?",
            areYouSureSaveChanges: "Weet u zeker dat u de openstaande wijzigingen wilt opslaan?"
        },
        success: {
            csvImportUsersFound: (count) => `Er zijn ${count} gebruikers gevonden in het bestand`,
            invitationsSend: "De uitnodigingen zijn verstuurd",
            invitationsSendQueue: "Het kan enkele minuten duren voordat de uitnodigingen verzonden zijn en worden getoond bij 'Openstaande uitnodigingen'.",
            changesHaveBeenSaved: "De wijzigingen zijn succesvol opgeslagen.",
            theFollowingGroupsHaveBeenSaved: "De volgende groepen zijn succesvol opgeslagen:"
        },
        validation: {
            numberRequired: "Dit veld mag alleen getallen bevatten.",
            hexadecimalRequired: "Dit veld mag alleen hexadecimale kleuren bevatten.",
            invalidOption: "Dit veld mag alleen de beschikbare opties bevatten.",
            invalidFileName: (fileName) => `De afbeelding die u probeert te uploaden heeft een incorrecte bestandsnaam. De bestandsnaam moet exact "${fileName}" zijn.`,
            invalidBoolean: "Dit veld mag alleen 'aan' of 'uit' staan.",
            requiredEmail: "Vul een geldig e-mailadres in",
            requiredField: "Verplicht veld"
        }
    }
};

export default nlNl;